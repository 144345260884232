import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { Box } from '@mui/material';

const GraficasIndicador = ({ datos, datosPorIndicador }) => {
    const meses = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

    // Verifica si 'IndProEmpresa' tiene el símbolo de porcentaje
    const esMetaPorcentaje = datos?.IndProEmpresa?.includes('%');

    // Construir la meta basado en el porcentaje
    const meta = Array.from({ length: 12 }, (_, i) => {
        const metaConPorcentaje = datos?.IndProEmpresa || "0%";
        return esMetaPorcentaje
            ? parseFloat(metaConPorcentaje.replace('%', '')) // Si tiene %, convertir a número
            : parseFloat(metaConPorcentaje);                // Si no tiene %, usar como decimal
    });

    // Obtener los resultados por cada mes, siempre como número para graficar
    const resultadoLinea = datosPorIndicador?.map((item) => {
        const resultado = item.DatIndRes || 0;
        return parseFloat(resultado);  // Usar el valor como número, sin símbolo %
    });

    // Encontrar los valores máximos y mínimos para ajustar los ejes
    const maxResult = Math.max(...resultadoLinea);
    const minResult = Math.min(...resultadoLinea);
    const maxMeta = Math.max(...meta);
    const minMeta = Math.min(...meta);

    useEffect(() => {
        // Inicializar el gráfico dentro de useEffect cuando el DOM esté listo
        const chartDom = document.getElementById('nightingale-chart');
        const chart = echarts.init(chartDom);

        const option = {
            tooltip: {
                trigger: 'axis',
            },
            legend: {
                data: ['Meta', 'Resultado Línea'],
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            // toolbox: {
            //     feature: {
            //         saveAsImage: {},
            //     },
            // },
            xAxis: [
                {
                    type: 'category',
                    data: meses,
                    axisPointer: {
                        type: 'shadow',
                    },
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    name: esMetaPorcentaje ? 'Meta (%)' : 'Meta (Valor)',
                    position: 'right',
                    min: Math.min(minMeta, minResult) - 10,  // Ajustar para mostrar espacio adicional
                    max: Math.max(maxMeta, maxResult) + 10,  // Ajustar para mostrar espacio adicional
                    axisLine: {
                        show: true,
                    },
                    axisLabel: {
                        formatter: function (value) {
                            if (esMetaPorcentaje) {
                                return Math.round(value) + '%';  // Mostrar valores redondeados con '%'
                            } else {
                                return Math.round(value);  // Mostrar valores redondeados sin '%'
                            }
                        }
                    },
                }
            ],

            series: [
                {
                    name: 'Meta',
                    type: 'line',
                    data: meta,
                    smooth: true,
                    yAxisIndex: 0, // Asociar la serie al eje Y
                    // lineStyle: {
                    //     color: '#1C64F2',
                    // },
                    markLine: {
                        silent: true,
                        data: [
                            { yAxis: meta[0] }, // Línea de referencia para la meta
                        ]
                    }
                },
                {
                    name: 'Resultado Línea',
                    type: 'line',
                    smooth: true,
                    data: resultadoLinea,
                    yAxisIndex: 0, // Asociar la serie al mismo eje Y
                    // lineStyle: {
                    //     color: '#E74C3C',
                    // },
                },
            ],
        };

        chart.setOption(option);

        function handleResize() {
            chart.resize();
        }

        window.addEventListener('resize', handleResize);

        // Aplicar la opción al gráfico
        chart.setOption(option);

        // Desmontar el gráfico cuando el componente se desmonte
        return () => {
            chart.dispose();
        };
    }, [datos, datosPorIndicador, meta, resultadoLinea, maxResult, minResult, maxMeta, minMeta]);

    return (
        <Box
            id="nightingale-chart"
            sx={{

                height: "70vh",
                width: '100%',
                padding: 2
            }}
        />
    );
};

export default GraficasIndicador;
