import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import './Inicio.css'
import "swiper/css";
import "swiper/css/pagination";
// import { Pagination, Autoplay } from "swiper/modules";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper/modules";
import logoFarma from "../../img/logosiglasfarmacomercial.png"
import neumuc from "../../img/Productos/farma/neumuc.png"

import video from "../../img/video/introFarma.mp4"
import {
    Box, Card, CardContent, CircularProgress, Grid,
    Typography, Container,
    Button,
    styled,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Divider
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import uno from "../../img/Productos/HISTAIR JARABE.jpeg"
import dos from "../../img/Productos/HISTAIR TABLETAS.jpeg"
import tres from "../../img/Productos/LOISTEN.jpg"
import cuatro from "../../img/Productos/NEUMUC 2.jpeg"
import cinco from "../../img/Productos/WhatsApp Image 2022-04-29 at 3.10.28 PM.jpeg"
import seis from "../../img/Productos/WhatsApp Image 2022-06-13 at 11.48.49 AM.jpeg"
import siete from "../../img/Productos/WhatsApp Image 2022-06-13 at 11.49.24 AM.jpeg"
import ocho from "../../img/Productos/WhatsApp Image 2022-09-07 at 9.07.16 AM (1).jpeg"
import nueve from "../../img/Productos/WhatsApp Image 2022-09-07 at 9.07.16 AM (2).jpeg"
import diez from "../../img/Productos/WhatsApp Image 2022-09-07 at 9.07.16 AM (3).jpeg"
import once from "../../img/Productos/WhatsApp Image 2022-09-07 at 9.07.16 AM (4).jpeg"
import doce from "../../img/Productos/WhatsApp Image 2022-09-07 at 9.07.16 AM.jpeg"
import newRequest from "../../../utils/newRequest";

import Footer from "../../../components/Footer/Footer";

const dataimgProd = [
    { index: 1, imagen: uno, name: "Histair jarabe" },
    { index: 2, imagen: dos, name: "Histair tableta" },
    { index: 3, imagen: tres, name: "loisten tableta" },
    { index: 4, imagen: cuatro, name: "Neumuc" },
    { index: 5, imagen: cinco, name: "Neumuc segunda presentación" },
    { index: 6, imagen: seis, name: "Resgripfar" },
    { index: 7, imagen: siete, name: "Bakzia" },
    { index: 8, imagen: ocho, name: "Histair" },
    { index: 9, imagen: nueve, name: "Loisten" },
    { index: 10, imagen: diez, name: "Neumuc tercera presentación" },
    { index: 11, imagen: once, name: "Resgripfar segunda presentación" },
    { index: 12, imagen: doce, name: "Histair segunda presentación" },
]

const TuComponente = ({ dataProductos }) => {

    const convertBytesToImageUrl = (bytes) => {
        if (bytes && bytes.length > 0) {
            const blob = new Blob([new Uint8Array(bytes)], { type: 'image/jpeg' });
            return URL.createObjectURL(blob);
        }
        return null;
    };

    return (
        <Swiper
            // className="custom-swiper"
            spaceBetween={30}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            loop
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 4, spaceBetween: 40 },
                992: { slidesPerView: 4, spaceBetween: 40 },
                1200: { slidesPerView: 5, spaceBetween: 40 },
                1400: { slidesPerView: 5, spaceBetween: 50 },
            }}

            modules={[Autoplay, Navigation, Pagination, Scrollbar]}

        >
            {dataProductos.map((producto, index) => (
                <SwiperSlide key={producto.ArtCod || index} >
                    <Card
                        sx={{
                            // width: '100%',
                            height: '300px',
                            borderRadius: '10px',
                            marginBottom: 1,
                        }}
                    >
                        <img
                            src={convertBytesToImageUrl(producto?.ArtImg?.data)}
                            alt={producto.ArtNom}
                            style={{
                                width: '100%',
                                height: '210px',
                                alignSelf: 'center',
                                aspectRatio: 3 / 2,
                                objectFit: 'contain',
                                // maskImage: "linear-gradient(black 80%, red)"
                            }}
                        />
                        <CardContent sx={{ justifyContent: 'center', alignItems: 'center', maxLines: 2 }}>
                            <Typography
                                variant="subtitle1"
                                color="text.secondary"
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'bold',
                                    maxLines: 2
                                }}
                            >
                                {producto.ArtNom}
                            </Typography>
                        </CardContent>
                    </Card>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

const Inicio = () => {
    const [loading, setLoading] = useState(true);
    const [dataProductos, setDataProductos] = useState([]);

    useEffect(() => {
        const productos = async () => {
            try {
                setLoading(true);
                const res = await newRequest.get(`/api/formulario/ProductosFarmaComercial`, {
                    secure: true,
                });
                setDataProductos(res?.data);
            } catch (error) {
                console.error("Error al obtener datos:", error);
            } finally {
                setLoading(false);
            }
        };

        if (!dataProductos.length) {
            productos();
        }

        window.scrollTo(0, 0);
    }, [dataProductos]);

    return (
        <>
            <section className="home" id="home">
                <video className="myVideo" autoPlay loop muted>
                    <source src={video} type="video/mp4" />
                </video>
            </section>

            <section>
                <div className="imagen-cartas"></div>
            </section>

            <Box sx={{
                width: "100%", paddingLeft: "3rem", paddingRight: "3rem",
                justifyContent: "center", alignItems: "center",
                '@media (min-width: 768px)': { marginTop: "-14rem" },
            }}>
                <Grid container spacing={1} alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={6} lg={3} sx={{ paddingTop: "2rem" }}>
                        <div className="Nuestros-productos" style={{
                            backgroundColor: "#003da5", height: "45px",
                            borderRadius: "15px 30px 50px 5px", alignItems: "center",
                            justifyItems: "center", display: "flex", marginBottom: 3,
                        }}>
                            <Typography variant="h5" sx={{
                                marginLeft: "10px", color: "white", overflow: 'hidden',
                                textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                            }}>
                                Nuestros productos
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {loading ? (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <div>
                                <TuComponente dataProductos={dataProductos} />
                            </div>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "1rem" }}>
                            <Button variant="text"
                            >Mas información..</Button>
                        </div>
                    </Grid>
                </Grid>
            </Box >


            <Grid container spacing={2} style={{ alignItems: "center", justifyContent: "center" }}>
                <Grid item xs={6} md={5}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <Typography variant="h6" style={{
                            textAlign: "end", display: "flex", justifyContent: "end",
                            alignItems: "center"
                        }}>SOBRE NOSOTROS</Typography>
                        <Typography variant="h2">¿QUIENES SOMOS?</Typography>
                    </div>
                </Grid>
                <div style={{
                    background: "#003da5", width: "2px",
                    height: "5rem", borderRadius: "10px", marginTop: "10px",
                }}></div>
                {/* <Divider orientation="vertical" style={{ width: "100%", marginTop: "1rem" }} /> */}

                <Grid item xs={6} md={5}>
                    <Typography variant='h6' gutterBottom sx={{ marginTop: "2rem", textAlign: "left" }}>
                        Organización colombiana soportada en la experiencia de un equipo humano interdisciplinado altamente competente, pone a disposición
                        y al alcance de toda la comunidad que desee  <span style={{
                            fontWeight: "bold",
                            marginLeft: "5px", marginRight: "5px"
                        }}>Vivir más y Vivir mejor, </span> productos para la salud como un componente fundamental para su bienestar y mejora de calidad
                    </Typography>
                </Grid>
            </Grid>


            <Box sx={{ marginTop: "8rem" }}>
                <Footer />
            </Box>
        </>
    )
}

export default Inicio