import React, { useEffect, useState } from "react";
import "./FormularioQuejas.css";
import {
    TextField,
    Button,
    Grid,
    Box,
    InputAdornment,
    Typography,
    Container
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import InventoryIcon from '@mui/icons-material/Inventory';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NumbersIcon from '@mui/icons-material/Numbers';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SendIcon from '@mui/icons-material/Send';
import Footer from "../../../components/Footer/Footer";
import newRequest from "../../../utils/newRequest";

export const FormularioQuejas = () => {
    const [formDataEmail, setFormDataEmail] = useState({
        subject: "",
        nic_cc: "",
        nombreCompleto: "",
        email: "",
        celular: "",
        nombreProducto: "",
        fecha: "",
        numeroLote: "",
        file: "",
        ciudad: null,
        descripcion: "",
    });

    const [ciudades, setCiudades] = useState([]);

    const handleInputChange = (event) => {
        setFormDataEmail({
            ...formDataEmail,
            [event.target.name]: event.target.value
        });
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setFormDataEmail({
            ...formDataEmail,
            pdf: file,
        });
    };

    const obtenerCiudades = async () => {
        try {
            const res = await newRequest.get(`/api/formulario/ciudades`);
            const ciudadesTransformadas = res.data.map(ciudad => ({
                value: ciudad.ciucod,
                label: ciudad.Ciudad
            }));
            setCiudades(ciudadesTransformadas);
        } catch (error) {
            console.log(error);
        }
    };

    // const clearFileInput = () => {
    //     const fileInput = document.getElementById("file-input");
    //     fileInput.value = null;
    //     setFormDataEmail((prevFormData) => ({
    //         ...prevFormData,
    //         file: "",
    //     }));
    // };

    // const sendEmail = (event) => {
    //     event.preventDefault();
    //     try {
    //         const formData = new FormData();
    //         formData.append("subject", formDataEmail.subject);
    //         formData.append('nic_cc', formDataEmail.nic_cc);
    //         formData.append('nombreCompleto', formDataEmail.nombreCompleto);
    //         formData.append('email', formDataEmail.email);
    //         formData.append('celular', formDataEmail.celular);
    //         formData.append('nombreProducto', formDataEmail.nombreProducto);
    //         formData.append('fecha', formDataEmail.fecha);
    //         formData.append('numeroLote', formDataEmail.numeroLote);
    //         formData.append('file', formDataEmail.pdf);
    //         formData.append('ciudad', ciudadSeleccionada.label);
    //         formData.append('descripcion', formDataEmail.descripcion);

    //         axios.post('http://localhost:8800/enviarEmail', formData)
    //             // axios.post('http://213.255.227.137:8800/enviarEmail', formData)
    //             .then(response => {
    //                 if (response.data.message === "ok") {
    //                     const emailId = response.data.emailId;

    //                     setFormDataEmail({
    //                         subject: "",
    //                         nic_cc: "",
    //                         nombreCompleto: "",
    //                         email: "",
    //                         celular: "",
    //                         nombreProducto: "",
    //                         fecha: "",
    //                         numeroLote: "",
    //                         file: null,
    //                         ciudad: "",
    //                         descripcion: "",
    //                     });

    //                     clearFileInput();

    //                     setFormDataEmail((prevFormData) => ({
    //                         ...prevFormData,
    //                         file: null, // o file: '' para un valor vacío
    //                     }));
    //                 }
    //             })
    //             .catch(() =>
    //                 Store.addNotification({
    //                     title: "Error",
    //                     message: "No se pudo enviar el correo",
    //                     type: "danger",
    //                     showIcon: true,
    //                     insert: "top",
    //                     container: "top-right",
    //                     animationIn: ["animate__animated", "animate__fadeIn"],
    //                     animationOut: ["animate__animated", "animate__fadeOut"],
    //                     dismiss: {
    //                         duration: 5000,
    //                         onScreen: true
    //                     },
    //                 })
    //             );
    //         return;
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    useEffect(() => {
        window.scrollTo(0, 0);
        obtenerCiudades();
    }, []);

    return (
        <Box>
            <Container>
                <Box style={{ width: "100%" }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '10em',
                        marginBottom: "2rem"
                    }}>
                        <Box sx={{
                            backgroundColor: "#003da5",
                            height: "45px",
                            width: "400px",
                            borderRadius: "15px 30px 15px 30px",
                        }}>
                            <Typography
                                sx={{
                                    marginLeft: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "white",
                                    justifyContent: "center",
                                    fontSize: "25px",
                                    textAlign: "center"
                                }}
                            >
                                PQRS
                            </Typography>
                        </Box>
                    </Box>

                    <section>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                        autoComplete="off"
                                        required
                                        name="subject"
                                        onChange={handleInputChange}
                                        placeholder="Motivo de la solicitud"
                                        variant="outlined"
                                        fullWidth
                                        value={formDataEmail.subject}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EventNoteIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{ sx: { marginLeft: "10px", ml: "25px" } }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                        autoComplete="off"
                                        required
                                        name="nic_cc"
                                        value={formDataEmail.nic_cc}
                                        onChange={handleInputChange}
                                        placeholder="Nit o cedula"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AssignmentIndIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{ sx: { marginLeft: "10px", ml: "25px" } }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                        autoComplete="off"
                                        required
                                        name="nombreCompleto"
                                        value={formDataEmail.nombreCompleto}
                                        onChange={handleInputChange}
                                        placeholder="Nombre completo"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircleIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{ sx: { marginLeft: "10px", ml: "25px" } }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                        autoComplete="off"
                                        required
                                        type="email"
                                        name="email"
                                        value={formDataEmail.email}
                                        onChange={handleInputChange}
                                        placeholder="Dirección de correco electronico"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{ sx: { marginLeft: "10px", ml: "25px" } }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                        autoComplete="off"
                                        required
                                        type="tel"
                                        name="celular"
                                        value={formDataEmail.celular}
                                        onChange={handleInputChange}
                                        placeholder="Ingrese numero"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ borderRadius: "10px" }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocalPhoneIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{ sx: { marginLeft: "10px", ml: "25px" } }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                        required
                                        name="nombreProducto"
                                        onChange={handleInputChange}
                                        value={formDataEmail.nombreProducto}
                                        placeholder="Nombre del producto"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ borderRadius: "10px" }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <InventoryIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{ sx: { marginLeft: "10px", ml: "25px" } }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                        required
                                        name="fecha"
                                        onChange={handleInputChange}
                                        type="date"
                                        value={formDataEmail.fecha}
                                        variant="outlined"
                                        fullWidth
                                        sx={{ borderRadius: "10px" }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CalendarMonthIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{ sx: { marginLeft: "10px", ml: "25px" } }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                        required
                                        name="numeroLote"
                                        value={formDataEmail.numeroLote}
                                        onChange={handleInputChange}
                                        placeholder="Numero del lote"
                                        variant="outlined"
                                        fullWidth
                                        sx={{ borderRadius: "10px" }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <NumbersIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{ sx: { marginLeft: "10px", ml: "25px" } }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <TextField
                                        required
                                        name="file"
                                        onChange={handleFileInputChange}
                                        type="file"
                                        id="file-input"
                                        variant="outlined"
                                        value={formDataEmail.file || ""}
                                        fullWidth
                                        sx={{ borderRadius: "10px" }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FileUploadIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        InputLabelProps={{ sx: { marginLeft: "10px", ml: "25px" } }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Autocomplete
                                        required
                                        disablePortal
                                        fullWidth
                                        name="ciudad"
                                        value={formDataEmail.ciudad || null}
                                        id="combo-box-demo"
                                        options={ciudades}
                                        getOptionLabel={(option) => option.label || ""}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        onChange={(event, newValue) => {
                                            setFormDataEmail({
                                                ...formDataEmail,
                                                ciudad: newValue
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Ciudad" />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        required
                                        name="descripcion"
                                        value={formDataEmail.descripcion}
                                        onChange={handleInputChange}
                                        id="outlined-basic"
                                        label="Descripción"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        InputProps={{ sx: { paddingLeft: "40px" } }}
                                        InputLabelProps={{ sx: { ml: "30px" } }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        style={{ borderRadius: "10px" }}
                                        endIcon={<SendIcon />}
                                    >
                                        Enviar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </section>
                </Box>
            </Container>
            <Box sx={{ marginTop: "8rem" }}>
                <Footer />
            </Box>
        </Box>
    );
};
