import React from 'react'
import { CardActionArea, Card, CardContent, CardMedia, Typography, Grid, Box } from '@mui/material'

import informacionPersonal from "../../../img/informacion-personal.png";
import devolucion from "../../../img/caja-de-devolucion.png";
import comercial from "../../../img/la-comercializacion-del-afiliado.png";
import desenchufado from "../../../img/desenchufado.png";
import calidad from "../../../img/calidad.png";
import autocontrol from "../../../img/autocontrol.png";
import transparencia from "../../../img/transparencia.png";
import drogas from "../../../img/drogas.png";
import vias from "../../../img/interseccion.png";
import acoso from "../../../img/exclusion-social.png";
// import computadora from "../../../img/computadora.png";

import uno from "../../../img/pdf/DE-OD-001 Política Protección de Datos.pdf"
import dos from "../../../img/pdf/GC-OD-001 V03 Politica de Devolucion.pdf"
import tres from "../../../img/pdf/GC-OD-002 Politica de Comercializacion.pdf"
import cuatro from "../../../img/pdf/GH-OD-003 Política de Desconexión Laboral.pdf"
import cinco from "../../../img/pdf/SG-OD-002 Política de Gestión de Calidad, Ambiental, Seguridad y Salud en el Trabajo.pdf"
import siete from "../../../img/pdf/SG-OD-005 Política de transparencia y ética empresarial.pdf"
import ocho from "../../../img/pdf/SG-OD-007 Politica de Control Sustancias Psicoactivas.pdf"
import nueve from "../../../img/pdf/SG-OD-009 Politica de Comportamiento Seguro en Vías.pdf"
import diez from "../../../img/pdf/SG-OD-010 Politica de Prevención del Acoso Laboral.pdf"
import once from "../../../img/pdf/TE-OD-002 Politica de Uso de TIC.pdf"


export const NuestrasPoliticas = () => {


    const principios = [
        { index: "1", imagen: informacionPersonal, texto: "Política Protección de Datos", pdf: uno },
        { index: "2", imagen: devolucion, texto: "Política de Devolución", pdf: dos },
        { index: "3", imagen: comercial, texto: "Política de Comercialización", pdf: tres },
        { index: "4", imagen: desenchufado, texto: "Política de Desconexión Laboral", pdf: cuatro },
        { index: "5", imagen: calidad, texto: "Política de Gestión de Calidad, Ambiental, Seguridad y Salud en el Trabajo", pdf: cinco },
        { index: "6", imagen: autocontrol, texto: "Política para el Autocontrol y la Gestión del Riesgo de LAFTFPADM", pdf: siete },
        { index: "7", imagen: transparencia, texto: "Política de transparencia y ética empresarial", pdf: ocho },
        { index: "8", imagen: drogas, texto: "Politica de Control Sustancias Psicoactivas", pdf: nueve },
        { index: "9", imagen: vias, texto: "Politica de Comportamiento Seguro en Vías", pdf: diez },
        { index: "10", imagen: acoso, texto: "Politica de Prevención del Acoso Laboral", pdf: once },
    ]


    return (

        <div>
            <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start', marginTop: '4em', }}>
                <Box sx={{ backgroundColor: "#d42b29", height: "45px", width: "400px", borderRadius: "15px 30px 30px 30px", }} >
                    <Typography sx={{
                        marginLeft: "10px", display: "flex", alignItems: "center",
                        color: "white", justifyContent: "center", fontSize: "25px", textAlign: "center"
                    }}
                    >
                        Politicas
                    </Typography>
                </Box>
            </Box>

            <Grid container spacing={2} sx={{ width: "100%", paddingLeft: "1rem" }}>
                {principios.map((item, index) => (
                    <Grid key={index} container item xs={12} md={3} lg={3} xl={2} sx={{ marginTop: "1rem" }} >
                        <CardActionArea download href={item.pdf} target="_blank">
                            <Card sx={{ maxWidth: 345, borderRadius: "13px" }}>
                                <CardMedia
                                    component="img"
                                    height="194"
                                    image={item.imagen}
                                    alt="Paella dish"
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                        margin: "0 auto",
                                        display: "block",
                                        marginTop: "25px"
                                    }}
                                />
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary"
                                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {item.texto}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}
