import React, { useEffect, useState } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Typography, IconButton, Button, Alert } from '@mui/material';

import {
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, ButtonGroup
} from '@mui/material';

import { MRT_Localization_ES } from 'material-react-table/locales/es';
import newRequest from '../../../utils/newRequest';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import AddCircleIcon from '@mui/icons-material/AddCircle';
// import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: "none",
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

// Función para formatear números como dinero
const formatCurrency = (value) => {
  return `$${new Intl.NumberFormat('es-ES').format(value)}`;
};

// Función para formatear números como dinero, incluyendo valores negativos
// const formatCurrency = (value) => {
//   const formattedValue = new Intl.NumberFormat('es-ES').format(Math.abs(value));
//   return value < 0 ? `-$${formattedValue}` : `$${formattedValue}`;
// };

// Función para formatear valores como dinero o cantidad
// const formatCurrency = (value, isCurrency) => {
//   if (isCurrency) {
//     const formattedValue = new Intl.NumberFormat('es-ES').format(Math.abs(value));
//     return value < 0 ? `-$${formattedValue}` : `$${formattedValue}`;
//   } else {
//     return value;  // Mostrar el valor tal cual si no es moneda
//   }
// };



const TablaIndicador = ({ obtenerIndicador, setOpenDrawer, year, DatoIndicador,
  onIdAnalisis, loading, indicadores, selectedIndicadoresId, onIDSelected,
  onDataIndicador, setOpenDrawerAnalisis, }) => {
  const meses = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

  const [id, setId] = useState("");
  const [idDos, setIdDos] = useState("");
  const [idAnalisis, setIdAnalisis] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const data = meses.map((mes, index) => {
    const monthIndex = index + 1;
    const mesData = obtenerIndicador.find(item => item.DatIndMes === monthIndex) || {};
    return {
      index: index + 1,
      indicador: indicadores[selectedIndicadoresId].IndProEmpSec,
      month: mes,
      signo: obtenerIndicador[0]?.IndProSig,
      year: year,
      datos: indicadores[selectedIndicadoresId],
      infoCompleta: obtenerIndicador,
      ...mesData,
    };
  });

  const handleButtonClick = (row) => {
    setId(row.DatIndSec);
  };

  const InsertarIndicador = (row) => {
    setOpenDrawer(true)
    setId("")
    onDataIndicador(row)
    onIDSelected("")
  }

  const InsertarAnalisis = (row) => {
    setIdAnalisis(row)
    setOpenDrawerAnalisis(true)
    onDataIndicador(row)
  }

  const handleOpenConfirmDialog = (row) => {
    setIdDos(row.DatIndSec); //Pasamos el parametro que recibimos a un estado para que pueda compartirse
    setOpenConfirmDialog(true); //Abrimos el modal
  };

  const EliminarunIndicador = async () => {
    try {
      await newRequest.delete(`/api/Calidad/InsertarIndicadores/EliminarIndicador/${idDos}`);
      toast.error('Dato eliminado correctamente', {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "dark",
      });
      DatoIndicador();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      const ObtenerUnDatoIndicador = async () => {
        try {
          const res = await newRequest.post(`/api/Calidad/InsertarIndicadores/ObtenerUnIndicador`, { id });
          onIDSelected(res.data);
          setId("");
          setIdDos("");
          setIdAnalisis("");
        } catch (error) {
          console.error(error);
        }
      };
      ObtenerUnDatoIndicador();
    }
    if (idAnalisis) {
      const ObtenerUnDatoAnalisis = async () => {
        try {
          const res = await newRequest.post(`/api/Calidad/InsertarIndicadores/ObtenerUnAnalisi`, {
            indicador: `${idAnalisis?.DatIndSec}`,
            mes: `${idAnalisis?.DatIndMes}`,
          });
          onIdAnalisis(res.data);
          setId("");
          setIdDos("");
          setIdAnalisis("");
        } catch (error) {
          console.error(error);
        }
      };
      ObtenerUnDatoAnalisis();
    }
  }, [id, idAnalisis, onIDSelected, onIdAnalisis]);


  const columns = [
    {
      accessorKey: 'month', // Nombre del mes
      // header: 'Fecha',
      header: (
        <Tooltip title='Fecha' arrow>
          <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textTransform: "lowercase",
              fontWeight: "normal"
            }}
          >
            Fecha
          </Typography>
        </Tooltip>
      ),
      // size: 70,
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'left',
        sx: {
          backgroundColor: '#e74c3c',
          color: "white",
          fontSize: '16px !important',

          fontWeight: 'normal',
          maxWidth: "8rem",
          width: "8rem",
        }
      },
      Cell: ({ cell, column }) => (
        <Box sx={{ display: "flex", alignItems: "start", justifyContent: "start", }}>
          <Typography variant='subtitle2' style={{ textTransform: 'capitalize' }}>
            {cell.row.original.month} - {cell.row.original.year}
          </Typography>
        </Box>
      ),
    },
    {
      accessorKey: 'IndProRel',
      header: (
        <Tooltip title={indicadores[selectedIndicadoresId]?.IndProRel} arrow>
          <Typography
            sx={{
              // fontSize: "14px",  // Ajusta este valor según tus necesidades
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textTransform: "lowercase",
              fontWeight: "normal"
            }}
          >
            {indicadores[selectedIndicadoresId]?.IndProRel}
          </Typography>
        </Tooltip>
      ),
      muiTableHeadCellProps: {
        align: "left",
        style: {
          backgroundColor: '#1C64F2',
          color: "white",
          fontWeight: 'normal',
        },
      },
      muiTableBodyCellProps: {
        align: 'left'
      },


      Cell: ({ cell }) => (
        <Typography variant='subtitle2' style={{ textTransform: 'capitalize' }}>
          {cell.row.original.DatIndDatUno >= 1000000 ? formatCurrency(cell.row.original.DatIndDatUno) : cell.row.original.DatIndDatUno}
        </Typography>
      ),
    },
    {
      accessorKey: 'IndProRelDos',
      header: (
        <Tooltip title={indicadores[selectedIndicadoresId]?.IndProRelDos}>
          <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textTransform: "lowercase",
              fontWeight: "normal"
            }}
          >
            {indicadores[selectedIndicadoresId]?.IndProRelDos}
          </Typography>
        </Tooltip>
      ),
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: "left",
        style: {
          backgroundColor: '#1C64F2',
          color: "white",
          fontWeight: 'normal',
        },
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      // Cell: ({ cell }) => (
      //   <Typography variant='subtitle2' style={{ textTransform: 'capitalize' }}>
      //     {cell.row.original.DatIndDatDos >= 1000000 ? formatCurrency(cell.row.original.DatIndDatDos) : cell.row.original.DatIndDatDos}
      //   </Typography>
      // ),
      Cell: ({ cell }) => (
        <Typography variant='subtitle2' style={{ textTransform: 'capitalize' }}>
         {Math.abs(cell.row.original.DatIndDatDos) >= 1000000
        ? formatCurrency(cell.row.original.DatIndDatDos)
        : cell.row.original.DatIndDatDos}
        </Typography>
      ),
    },
    {
      accessorKey: 'DatIndRes',
      header: 'Resultado',
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'left',
        sx: {
          backgroundColor: '#27AE60',
          color: "white", fontSize: "15px",
          fontWeight: 'normal',
        }
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
      // size: 90,
      // Cell: ({ cell, column }) => {
      //   return (
      //     <Box>
      //       <Typography variant='subtitle2' style={{ textTransform: 'capitalize' }}>
      //         {cell.row.original.DatIndRes}
      //       </Typography>
      //     </Box>
      //   )
      // },
    },
    {
      accessorKey: 'DatIndAcum',
      header: 'Acomulado',
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'left',
        sx: {
          backgroundColor: '#27AE60',
          color: "white", fontSize: "15px",
          fontWeight: 'normal',

        }
      },
      // size: 90,
      muiTableBodyCellProps: {
        align: 'left',
      },
      // Cell: ({ cell, column }) => {
      //   return (
      //     <Box>
      //       <Typography variant='subtitle2' style={{ textTransform: 'capitalize' }}>
      //         {cell.row.original.DatIndAcum}
      //       </Typography>
      //     </Box>
      //   )
      // },
    },
    {
      accessorKey: 'DatIndResPro',
      header: 'Promedio',
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'left',
        sx: {
          backgroundColor: '#27AE60',
          color: "white", fontSize: "15px",
          fontWeight: 'normal',
        }
      },
      muiTableBodyCellProps: {
        align: 'left',
      },
    },
    {
      accessorKey: 'Opciones',
      header: 'Opciones',
      enableColumnActions: false,
      muiTableHeadCellProps: {
        align: 'center',
        sx: {
          backgroundColor: '#1C64F2',
          color: "white", fontSize: "15px",
          fontWeight: 'normal',
        }
      },
      // size: 90,
      muiTableBodyCellProps: {
        align: 'center',
        height: "7px"
      },
      Cell: ({ cell, row }) => {
        // Determina si la fila anterior tiene datos
        const rowIndex = row.index; //  se utiliza para obtener el índice de la fila actual en la tabla.
        const currentRow = data[rowIndex]; //  almacena los datos de la fila actual utilizando el índice rowIndex.
        const previousRow = data[rowIndex - 1];
        const rowHasData = currentRow.DatIndMes && (currentRow.DatIndDatUno || currentRow.DatIndDatDos);
        const previousRowHasData = previousRow?.DatIndMes && (previousRow?.DatIndDatUno || previousRow?.DatIndDatDos);
        const rowsWithDataCount = data.filter(row => row.DatIndMes && (row.DatIndDatUno || row.DatIndDatDos)).length;
        const isLastRowWithData = rowIndex === rowsWithDataCount - 1;

        return (
          <div style={{ marginTop: "-5px", marginBottom: "-5px" }}>
            <ButtonGroup variant="text">
              {rowHasData ? (
                // Muestra las opciones "Editar" y "Analizar" si la fila actual tiene datos
                <>

                  <ButtonGroup variant="text" aria-label="Basic button group">
                    {/* EDITAR INDICADOR  */}
                    {/* {isLastRowWithData && (
                      <div>
                        <Button onClick={() => handleButtonClick(cell.row.original)}>
                          <EditIcon sx={{ width: '23px', height: '23px', color: '#27AE60' }} />
                        </Button>
                      </div>
                    )} */}
                    <Button onClick={() => InsertarAnalisis(cell.row.original)}>
                      <ManageSearchIcon sx={{ width: '23px', height: '23px', color: '#FF5722' }} />
                    </Button>
                    {isLastRowWithData && (
                      <div>
                        <Button onClick={() => handleOpenConfirmDialog(cell.row.original)}>
                          <DeleteIcon sx={{ width: '23px', height: '23px', color: '#FF2442' }} />
                        </Button>
                      </div>
                    )}
                  </ButtonGroup>
                </>
              ) : (
                (rowIndex === 0 || previousRowHasData) ? (
                  <>
                    {/* AGREGAR DATOS AL INDICADOR */}
                    <Button onClick={() => InsertarIndicador(cell.row.original)}
                      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <AddCircleIcon sx={{ width: '23px', height: '23px', color: '#118DF0' }} />
                    </Button>
                  </>
                ) : null
              )}
            </ButtonGroup>
          </div>
        );
      },
    }
  ];


  const table = useMaterialReactTable({
    columns,
    data,
    muiTablePaperProps: {
      elevation: 0
    },
    initialState: { density: 'compact' },
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    enableDensityToggle: false,
    enableFilters: false,
    enableHiding: false, // Deshabilita el mostrar y ocultar columas

    enableStickyHeader: true,
    enableStickyFooter: true,

    enableColumnActions: false,
    enableSorting: false,
    localization: MRT_Localization_ES,

    enableTableFooter: false,

    enableGlobalFilter: false,

    enablePagination: false,

    // Ocultar completamente la barra superior
    // renderTopToolbar: false,
    renderBottomToolbar: false,
    muiTableContainerProps: {
      sx: {
        width: "100%",
        height: { xs: "25vh", sm: "60vh", md: "60vh", lg: "60vh", xl: "60vh" },

      }
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">Información: </Typography>
            <Typography>
              <span style={{ fontWeight: "bold" }}>resultado: </span>
              es la Operacion, asignada en la fila sea <span style={{ fontWeight: "bold" }}>Suma </span>,
              <span style={{ fontWeight: "bold" }}> Resta</span> ,<span style={{ fontWeight: "bold" }}> Multiplicación</span> o
              <span style={{ fontWeight: "bold" }}> División</span>
            </Typography>
            <Typography>
              <span style={{ fontWeight: "bold" }}>Acomulado: </span>
              Suma total de las columnas <span style={{ fontWeight: "bold" }}>{data[0].IndProRel}</span>  y
              <span style={{ fontWeight: "bold" }}>  {data[0].IndProRelDos}</span>
            </Typography>
            <Typography>
              <span style={{ fontWeight: "bold" }}>Promedio: </span>
              Suma de la columna de <span style={{ fontWeight: "bold" }}>Resultados</span> Divido la
              <span style={{ fontWeight: "bold" }}> Cantidad</span> de filas
            </Typography>

          </React.Fragment>
        }
      >
        <Tooltip arrow >
          <IconButton >
            <InfoIcon style={{ padding: 0 }} />
          </IconButton>
        </Tooltip>
      </HtmlTooltip>
    ),
  })


  return (
    <div>
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant='h5'>
            Confirmar Eliminación
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant='h6'>
              ¿Está seguro de que desea eliminar este indicador?
            </Typography>

            <Alert icon={false} severity="info" sx={{ marginTop: 1 }}>
              <Typography variant='subtitle2'>
                Tener en cuenta, que el dato que se va a <span style={{ fontWeight: "bold" }}>eliminar</span>  tambien eliminara el analisis creado
              </Typography>
            </Alert>

            {/* <span style={{ fontWeight: "bold" }}>
            </span> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button sx={{ color: "red" }} onClick={() => {
            setOpenConfirmDialog(false);
            // Llamar a la función para eliminar el proceso aquí
            EliminarunIndicador();
          }} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* <MaterialReactTable data={data} columns={columns}
        muiTablePaperProps={{
          elevation: 0,
        }}
        enableStickyHeader
        muiTableBodyCellProps={{
          sx: {
            // fontSize: '0.9rem !important',
            whiteSpace: "nowrap",
            height: "45px !important",
            overflow: "hidden",
            textOverflow: "ellipsis",
            // border: '0.5px solid rgba(224, 224, 224, 1)',
          },
        }}
        initialState={{
          pagination: { pageIndex: 0, pageSize: 15 },
          density: "compact"
        }}
        state={{ isLoading: loading }}
        muiCircularProgressProps={{
          color: 'primary',
          thickness: 5,
          size: 55,
        }}
        // muiSkeletonProps={{
        // animation: 'pulse',
        // height: 28,
        // }}

        enableColumnFilters={false}
        enableColumnActions={false}
        enableFullScreenToggle={false}
        enableHiding={false}
        enableFilters={false}
        enableSorting={false}
        enableColumnOrdering={false}
        enableDensityToggle={false}

        muiTableBodyRowProps={{ hover: false }}
        renderTopToolbarCustomActions={() => (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">Información: </Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>resultado: </span>
                  es la Operacion, asignada en la fila sea <span style={{ fontWeight: "bold" }}>Suma </span>,
                  <span style={{ fontWeight: "bold" }}> Resta</span> ,<span style={{ fontWeight: "bold" }}> Multiplicación</span> o
                  <span style={{ fontWeight: "bold" }}> División</span>
                </Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>Acomulado: </span>
                  Suma total de las columnas <span style={{ fontWeight: "bold" }}>{data[0].IndProRel}</span>  y
                  <span style={{ fontWeight: "bold" }}>  {data[0].IndProRelDos}</span>
                </Typography>
                <Typography>
                  <span style={{ fontWeight: "bold" }}>Promedio: </span>
                  Suma de la columna de <span style={{ fontWeight: "bold" }}>Resultados</span> Divido la
                  <span style={{ fontWeight: "bold" }}> Cantidad</span> de filas
                </Typography>

              </React.Fragment>
            }
          >
            <Tooltip arrow >
              <IconButton >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </HtmlTooltip>
        )}
        localization={MRT_Localization_ES}
        // muiTableContainerProps={{ sx: { height: 395, width: "100%" } }}
        muiTableContainerProps={{
          sx: {
            height: { xs: "25vh", sm: "40vh", md: "40vh", lg: "40vh", xl: "48vh" },
            width: "100%",
            overflowX: 'auto', // Para permitir el scroll horizontal en pantallas pequeñas
          }
        }}
        muiTableProps={{
          sx: {
            tableLayout: 'auto', // Para permitir que la tabla se ajuste automáticamente
            '@media (max-width: 600px)': {
              '& .MuiTableCell-root': {
                padding: '8px', // Ajusta el padding para pantallas pequeñas
                fontSize: '12px', // Ajusta el tamaño de fuente para pantallas pequeñas
              },
            },

          },
        }}
      /> */}


      <MaterialReactTable table={table} />
    </div>
  )
}

export default TablaIndicador