import React from "react";
import { Typography, Button, TextField, Grid, Box, Fab } from '@mui/material';
import emailjs from "@emailjs/browser";
import "./Footer.css";
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import WorkIcon from '@mui/icons-material/Work';
import { styled } from '@mui/material/styles';

import logoFarma from "../../PaginaWeb/img/logosiglasfarmacomercial.png"

const Footer = () => {
    const CssTextField = styled(TextField)({
        '& label.Mui-focused': {
            color: 'black',
            // color: '#A0AAB4',
        },
        '& .MuiInput-underline:after': {
            // borderBottomColor: '#B2BAC2',
            borderBottomColor: 'black',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black',
                // borderColor: '#E0E3E7',
            },
            '&:hover fieldset': {
                // borderColor: '#B2BAC2',
                borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
                // borderColor: '#6F7E8C',
            },
        },
    });


    // const phoneNumber = '+573162908167'; // número de teléfono al que se enviará el mensaje
    // const message = '¡Hola!'; // mensaje que se enviará
    // const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    const sendEmail = (event) => {
        event.preventDefault();
        emailjs.sendForm(
            'service_mmugce9',
            'template_z6q79rh',
            event.target,
            'CC7QB8mUXlG9gg-0z'
        )
            .then(response => {
                event.target.reset();
                // Swal.fire({
                //     icon: 'success',
                //     title: 'Correo enviado correctamente',
                //     showConfirmButton: false,
                //     timer: 1500
                // })
            })
            .catch(error => console.log(error))
    }
    return (
        <div className="footer-imagen">
            <Box>
                <img src={logoFarma} alt="" style={{ width: "200px", margin: "auto", display: "block" }} />
            </Box>

            <div style={{
                alignContent: "center", justifyContent: "center",
                marginLeft: "4em", marginRight: "4em", marginTop: "4rem",
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Box
                            sx={{
                                backgroundColor: "#003da5", height: "45px", borderRadius: "50px 30px 50px 50px",
                                alignItems: "center", justifyItems: "center", display: "flex",
                            }}
                        >
                            <Fab color="white" size="small">
                                <EmailIcon sx={{ color: "#003da5" }} />
                            </Fab>
                            <Typography
                                component="span"
                                sx={{
                                    marginLeft: "4px", color: "white", fontSize: "18px", display: "flex",
                                    alignContent: "center", justifyContent: "center", alignItems: "center",
                                    overflow: 'auto', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}
                            >
                                <Typography component="span" sx={{
                                    marginLeft: "4px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}>
                                    comercial@laboratoriofarmacomercial.com
                                </Typography>
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                backgroundColor: "#003da5", height: "45px", marginTop: "20px", borderRadius: "50px 30px 50px 50px",
                                alignItems: "center", justifyItems: "center", display: "flex",
                            }}
                        >
                            <Fab color="white" size="small">
                                <PhoneAndroidIcon sx={{ color: "#003da5" }} />
                            </Fab>
                            <Typography
                                component="span"
                                sx={{
                                    marginLeft: "5px", color: "white", fontSize: "18px", display: "flex",
                                    alignContent: "center", justifyContent: "center", alignItems: "center",
                                    overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}
                            >
                                Celular:
                                <Typography component="span" sx={{
                                    marginLeft: "5px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}>
                                    316 290 8167
                                </Typography>
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                backgroundColor: "#003da5", height: "45px", marginTop: "20px", borderRadius: "50px 30px 50px 50px",
                                alignItems: "center", justifyItems: "center", display: "flex",
                            }}
                        >
                            <Fab color="white" size="small">
                                <FacebookIcon sx={{ color: "#003da5" }} />
                            </Fab>
                            <Typography
                                component="span"
                                sx={{
                                    marginLeft: "5px", color: "white", fontSize: "18px", display: "flex",
                                    alignContent: "center", justifyContent: "center", alignItems: "center",
                                    overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}
                            >
                                Facebook:
                                <Typography component="span" sx={{
                                    marginLeft: "5px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}>
                                    <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/Labfacom/"
                                        style={{ textDecoration: "none", color: "white" }}>
                                        Laboratorio Farma Comercial
                                    </a>
                                </Typography>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                backgroundColor: "#003da5", height: "45px", marginTop: "20px", borderRadius: "50px 30px 50px 50px",
                                alignItems: "center", justifyItems: "center", display: "flex",
                            }}
                        >
                            <Fab color="white" size="small">
                                <InstagramIcon sx={{ color: "#003da5" }} />
                            </Fab>
                            <Typography
                                component="span"
                                sx={{
                                    marginLeft: "5px", color: "white", fontSize: "18px", display: "flex",
                                    alignContent: "center", justifyContent: "center", alignItems: "center",
                                    overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}
                            >
                                Instagram:
                                <Typography component="span" sx={{
                                    marginLeft: "5px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}>
                                    <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/labfacom/"
                                        style={{ textDecoration: "none", color: "white" }}>
                                        Labfacom
                                    </a>
                                </Typography>
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <Box
                            sx={{
                                backgroundColor: "#003da5", height: "45px", borderRadius: "50px 30px 50px 50px",
                                alignItems: "center", justifyItems: "center", display: "flex",
                            }}
                        >
                            <Fab color="white" size="small">
                                <CameraAltIcon sx={{ color: "#003da5" }} />
                            </Fab>
                            <Typography
                                component="span"
                                sx={{
                                    marginLeft: "5px", color: "white", fontSize: "18px", display: "flex",
                                    alignContent: "center", justifyContent: "center", alignItems: "center",
                                    overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}
                            >
                                FarmaCovigilancia
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                backgroundColor: "#003da5", height: "45px", marginTop: "20px", borderRadius: "50px 30px 50px 50px",
                                alignItems: "center", justifyItems: "center", display: "flex",
                            }}
                        >
                            <Fab color="white" size="small">
                                <WorkIcon sx={{ color: "#003da5" }} />
                            </Fab>
                            <Typography
                                component="span"
                                sx={{
                                    marginLeft: "5px", color: "white", fontSize: "18px", display: "flex",
                                    alignContent: "center", justifyContent: "center", alignItems: "center",
                                    overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'
                                }}
                            >
                                <a rel="noopener noreferrer" target="_blank" href="http://162.251.146.228:8080/MantisFarmaComercial/servlet/com.version8.wwaspvacanteextr"
                                    style={{ textDecoration: "none", color: "white" }}>
                                    Trabaja con nosotros
                                </a>
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <form action="" onSubmit={sendEmail}>
                            <CssTextField label="Nombre Completo" fullWidth autoComplete="off" margin="dense" required name="user_name" size="small" style={{ borderRadius: 15 }} />
                            <CssTextField label="Email" fullWidth type="email" margin="dense" required name="user_email" autoComplete="off" size="small" style={{ marginTop: 11 }} />
                            <CssTextField label="Celular" fullWidth type="tel" autoComplete="off" margin="dense" required name="user_numero" size="small" style={{ marginTop: 11 }} />
                            <CssTextField fullWidth required multiline label="Mensaje" autoComplete="off" size="small" id="reddit-input" maxRows={4} margin="dense" name="user_mensaje" style={{ marginTop: 11 }} />
                            <Button
                                variant="outlined"
                                fullWidth
                                type="submit"
                                sx={{
                                    color: 'white',
                                    marginTop: '10px',
                                    background: '#003da5',
                                    '&:hover': {
                                        background: '#393e46',
                                    },
                                }}
                                startIcon={<i className="fa-solid fa-paper-plane"></i>}
                            >
                                Enviar
                            </Button>
                        </form>
                    </Grid>
                </Grid>

                <Box sx={{ color: "white", paddingTop: "3rem", paddingBottom: "1rem" }}>
                    <div className="container">
                        <Typography style={{ fontSize: "18px", margin: "auto", textAlign: "center" }}>
                            © 2024 All Rights Reserved. Gerencia@laboratoriofarmacomercial.com |+57 3162908167 / +601 8759262
                        </Typography>
                    </div>
                </Box>
            </div>

        </div>

    )
}

export default Footer