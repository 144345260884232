import { createContext, useEffect, useState } from "react";
import newRequest from "./utils/newRequest";
import Cookies from "js-cookie";
// import { useNavigate } from "react-router-dom";
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null);

    const storedUser = JSON.parse(localStorage.getItem("user"));
    const initialIsAuthenticated = !!storedUser; // Convertir a booleano
    const [isAuthenticated, setIsAuthenticated] = useState(initialIsAuthenticated);

    // const navigate = useNavigate()

    const login = async (inputs) => {
        const res = await newRequest.post('/api/login/sesion', inputs, {
            withCredentials: true,
        });
        if (res.status === 200) {
            setIsAuthenticated(true);
            setCurrentUser(res.data)
            // navigate("/Inicio");
            // window.location.href = "/Inicio";
        }
    };

    const logout = () => {
        Cookies.remove("token"); // Eliminar la cookie del token al cerrar sesión
        Cookies.remove("session_id"); // Eliminar la cookie del token al cerrar sesión
        setCurrentUser(null);
        setIsAuthenticated(false);
        localStorage.removeItem("token"); // Eliminar el valor del almacenamiento local al cerrar sesión
        localStorage.removeItem("session_id"); // Eliminar el valor del almacenamiento local al cerrar sesión
        localStorage.removeItem('selectedIndex')
        newRequest.post('/api/login/cerrarSesion', {
            withCredentials: true,
        })
    };

    useEffect(() => {
        // Verificar si hay un valor de autenticación en el almacenamiento local al cargar la aplicación
        const storedUser = JSON.parse(localStorage.getItem("user"));
        if (storedUser) {
            setCurrentUser(storedUser);
            setIsAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("user", JSON.stringify(currentUser));
    }, [currentUser]);

    // ***************************************************************

    return (
        <AuthContext.Provider value={{
            currentUser, login, setCurrentUser,
            logout, isAuthenticated
        }}>
            {children}
        </AuthContext.Provider>
    );
}