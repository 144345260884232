import React, { useContext, useState } from 'react';
import { CssBaseline, List, Container, Button, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Divider, Drawer, IconButton, Box, Menu, MenuItem, AppBar } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import uno from "../../images/logo-farmacomercial.png";
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { AuthContext } from '../../loginContext';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import 'react-toastify/dist/ReactToastify.css';
import SimpleDialog from './SimpleDialog';

const drawerWidth = 240;

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const DrawerContent = ({ handleDrawerToggle }) => (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ my: 2 }}>
            Farma Comercial
        </Typography>
        <Divider />
        <List>
            {[
                { text: 'Inicio', path: '/' },
                { text: '¿Quienes somos?', path: '/QuienesSomos' },
                { text: 'Eventos', path: '/Eventos' },
                { text: 'Portafolio', path: '/Portafolio' },
                { text: 'Contactenos', path: '/Contacto' },
                { text: 'Iniciar sesión', path: '/login' }
            ].map(({ text, path }, index) => (
                <ListItem disablePadding key={text}>
                    <ListItemButton sx={{ textAlign: 'left' }} component={Link} to={path}>
                        <ListItemText primary={text} />
                        {index === 5 && <AccountCircleIcon sx={{ marginRight: 1, fontSize: "20px" }} />}
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    </Box>
);

const NavbarInicio = (props) => {
    const { isAuthenticated, currentUser, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const { window } = props;

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleCloseMenu = () => setAnchorEl(null);

    const cerrarSesion = () => {
        logout();
        navigate("/");
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const menuItems = [
        { text: 'Inicio', path: '/' },
        { text: '¿Quienes somos?', path: '/QuienesSomos' },
        { text: 'Eventos', path: '/Eventos' },
        { text: 'Portafolio', path: '/Portafolio' },
        { text: 'Contactenos', path: '/Contacto' }
    ];

    return (
        <Box>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar elevation={0} component="nav" sx={{ background: "#F9F9F9" }}>
                    <Container maxWidth={'xl'}>
                        <Toolbar>
                            <IconButton
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' }, background: "#003da5" }}
                            >
                                <MenuIcon sx={{ color: "white" }} />
                            </IconButton>
                            <Box sx={{ flexGrow: 1, display: { sm: 'block' } }}>
                                <img src={uno} width="250px" style={{ marginTop: "15px" }} alt="Farma Comercial" />
                            </Box>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <Box sx={{ color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {menuItems.map(({ text, path }) => (
                                        <Typography key={text} variant='h6' component={Link} to={path}
                                            color="text.secondary"
                                            sx={{ marginLeft: "25px", textDecoration: "none" }}>
                                            {text}
                                        </Typography>
                                    ))}
                                    <Typography variant='h6' sx={{ marginLeft: "18px", color: "#0000008C", fontSize: "1.2rem" }}>
                                        {isAuthenticated ? (
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Button variant='text'
                                                    aria-label="more"
                                                    aria-controls={open ? 'long-menu' : undefined}
                                                    aria-expanded={open ? 'true' : undefined}
                                                    aria-haspopup="true"
                                                    sx={{ color: "white" }}
                                                    onClick={handleClick}
                                                >
                                                    <Typography variant='body2' sx={{ color: "#0000008C", fontSize: "1.2rem" }}>
                                                        {currentUser.userData?.UsuCod}
                                                    </Typography>
                                                </Button>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={openMenu}
                                                    onClose={handleCloseMenu}
                                                    MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                                                >
                                                    <MenuItem component={Link} to="/Plataformas">
                                                        <Typography variant='body2' sx={{ color: "#0000008C", textDecoration: "none", fontSize: "1.2rem" }}>
                                                            Plataformas
                                                        </Typography>
                                                    </MenuItem>
                                                    <MenuItem onClick={cerrarSesion}>
                                                        <Typography variant='body2' sx={{ color: "#0000008C", textDecoration: "none", fontSize: "1.2rem" }}>
                                                            Cerrar sesión
                                                        </Typography>
                                                    </MenuItem>
                                                </Menu>
                                            </div>
                                        ) : (
                                            <IconButton onClick={handleClickOpen}>
                                                <AccountCircleOutlinedIcon />
                                            </IconButton>
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </ElevationScroll>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    <DrawerContent handleDrawerToggle={handleDrawerToggle} />
                    <SimpleDialog open={open} onClose={handleClose} />
                </Drawer>
            </nav>
        </Box>
    );
};

export default NavbarInicio;
