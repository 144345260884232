import React from 'react'
import "./Principio_valores.css";
import { Typography, Grid, Box } from '@mui/material';

import HandshakeIcon from '@mui/icons-material/Handshake';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import LockPersonIcon from '@mui/icons-material/LockPerson';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Groups3Icon from '@mui/icons-material/Groups3';
import PsychologyIcon from '@mui/icons-material/Psychology';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import WavingHandIcon from '@mui/icons-material/WavingHand';

export const PrincipioValores = () => {


    const cartas = [
        { icono: <HandshakeIcon style={{ fontSize: '3rem' }} />, letra: 'R', titulo: 'Respeto', contenido: 'Dignidad, virtudes, diferencias y limitaciones, reconociendo autonomía y autoridad.' },
        { icono: <Diversity3Icon style={{ fontSize: '3rem' }} />, letra: 'I', titulo: 'Integridad', contenido: 'Todos somos parte de la misma organización y unimos esfuerzos para mantener el logro de los objetivos.' },
        { icono: <VisibilityIcon style={{ fontSize: '3rem' }} />, letra: 'T', titulo: 'Transparencia', contenido: 'Prudencia, sinceridad, coherencia y comportamientos dignos de confianza.' },
        { icono: <WbSunnyIcon style={{ fontSize: '3rem' }} />, letra: 'A', titulo: 'Armonía', contenido: 'Voluntad de prevenir conflictos ante eventuales desavenencias, diferencias y/o divergencias de criterios.' },
        { icono: <LockPersonIcon style={{ fontSize: '3rem' }} />, letra: 'C', titulo: 'Confidencialidad', contenido: 'Manejar absoluta y estricta reserva profesional para no revelar información restringida y privada de la organización de manera indefinida.' },
    ];

    const cartasDos = [
        { icono: <LightbulbIcon style={{ fontSize: '3rem' }} />, letra: 'I', titulo: 'Innovación', contenido: 'Generación de conocimiento, y perfeccionamiento de procesos y productos, con el aporte de desarrollos tecnológicos, trascendiendo fronteras.' },
        { icono: <Groups3Icon style={{ fontSize: '3rem' }} />, letra: 'C', titulo: 'Confianza', contenido: 'Generación de ambientes, productos y resultados seguros, con experiencias de desarrollo multidimensional.' },
        { icono: <PsychologyIcon style={{ fontSize: '3rem' }} />, letra: 'C', titulo: 'Coherencia', contenido: 'Desempeño Actuar alineados a las políticas y estándares promulgados por la organización.' },
        { icono: <VolunteerActivismIcon style={{ fontSize: '3rem' }} />, letra: 'B', titulo: 'Bienestar', contenido: 'Asegurar satisfacción integral a todos los grupos de interés.' },
        { icono: <WavingHandIcon style={{ fontSize: '3rem' }} />, letra: 'C', titulo: 'Colaboración', contenido: 'Servir y trabajar por el desarrollo sostenible desde nuestra participación en el mercado.' },
    ];


    return (

        <section>

            <Box sx={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'start',
                marginTop: '4em',
            }}>
                <Box sx={{
                    backgroundColor: "#d42b29",
                    height: "45px",
                    width: "400px",
                    borderRadius: "30px 15px 30px 30px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                    <Typography
                        sx={{
                            color: "white",
                            fontSize: "25px",
                            textAlign: "center",
                        }}
                    >
                        Principios
                    </Typography>
                </Box>
            </Box>

            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ gap: "3rem " }}>
                {cartas.map((carta, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2} sx={{
                        marginTop: '1rem', width: "100%"
                    }}>
                        <div className="flip-container">
                            <div className="card-flip">
                                <div className="front">
                                    <span >
                                        {carta.icono}
                                        <Typography variant='h3' style={{ marginTop: '-1rem' }}>{carta.letra}</Typography>
                                    </span>
                                </div>
                                <div className="back">
                                    <span>
                                        <Typography variant="h5" style={{ fontSize: '30px' }}>
                                            {carta.titulo}
                                        </Typography>
                                        <Typography variant="body1" style={{ fontSize: '15px', padding: 3 }}>{carta.contenido}</Typography>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>

            <Box sx={{
                display: 'flex', alignItems: 'end',
                justifyContent: 'end', marginTop: '4em',
            }}>
                <Box sx={{
                    backgroundColor: "#d42b29", height: "45px",
                    width: "400px", borderRadius: "30px 15px 30px 30px",
                }}
                >
                    <Typography
                        sx={{
                            marginLeft: "10px", display: "flex",
                            alignItems: "center",
                            color: "white", justifyContent: "center",
                            fontSize: "25px", textAlign: "center"
                        }}
                    >
                        Valores
                    </Typography>
                </Box>
            </Box>

            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ gap: "3rem" }}>
                {cartasDos.map((carta, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2} sx={{
                        marginTop: '1rem', width: "100%",
                    }}>
                        <div className="flip-container">
                            <div className="card-flip">
                                <div className="front">
                                    <span>
                                        {carta.icono}
                                        <Typography variant='h3' style={{ marginTop: '-1rem' }}>{carta.letra}</Typography>
                                    </span>
                                </div>
                                <div className="back">
                                    <span>
                                        <Typography variant="h5" style={{ fontSize: '30px' }}>{carta.titulo}</Typography>
                                        <Typography variant="body1" style={{ fontSize: '15px', padding: 3 }}>{carta.contenido}</Typography>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </section>
    )
}


