import React from 'react'
import { Box, Grid, Typography, Container } from '@mui/material';
// import uno from "../../img/QuienesSomos/mapa-farma.png"
import Footer from '../../../components/Footer/Footer';
import { PrincipioValores } from './Principio_valores/Principio_valores';
import { NuestrasPoliticas } from './NuestrasPoliticas/NuestrasPoliticas';

const QuienesSomos = () => {
    return (
        <div >
            <Container fixed>
                <Box style={{ width: "100%" }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '7em',
                    }}>
                        <Box sx={{
                            backgroundColor: "#003da5",
                            height: "45px", width: "400px",
                            borderRadius: "15px 30px 15px 30px",
                        }}
                        >
                            <Typography variant='h5'
                                sx={{
                                    marginLeft: "10px", display: "flex",
                                    alignItems: "center", marginTop: 0.5,
                                    color: "white", justifyContent: "center",
                                    textAlign: "center"
                                }} >
                                ¿Quiénes somos?
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ width: "100%", }}>
                        <Grid container spacing={2} sx={{ marginTop: "0.5em", textAlign: "center", display: "flex", }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant='h6' gutterBottom sx={{ marginTop: "2rem", textAlign: "left" }}>
                                    Organización colombiana soportada en la experiencia de un equipo humano interdisciplinado altamente competente, pone a disposición
                                    y al alcance de toda la comunidad que desee  <span style={{
                                        fontWeight: "bold",
                                        marginLeft: "5px", marginRight: "5px"
                                    }}>Vivir más y Vivir mejor, </span> productos para la salud como un componente fundamental para su bienestar y mejora de calidad
                                </Typography>
                                <Typography variant='h6' gutterBottom sx={{ marginTop: "1rem", textAlign: "left" }} >
                                    Contamos con representantes de ventas que le orientará directamente en todo el territorio, nacional, con presencia en las
                                    <span style={{ fontWeight: "bold", marginLeft: "5px", marginRight: "5px" }}> Zonas, regionales del Centro Cundiboyacenses; Llanos Orientales; Eje Cafetero; Cauca y Valle del Cauca; Sur colombiano
                                        (Huila, Tolima, Caquetá); Costa caribe y Santanderes</span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid container spacing={3} sx={{ marginTop: "-4.5rem" }}>
                        <Grid item xs={12} sm={6} md={6} lg={6} >
                            <Box sx={{
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'center', marginTop: '6em',
                            }}>
                                <Box sx={{
                                    backgroundColor: "#d42b29", height: "45px",
                                    width: "400px", borderRadius: "15px 30px 15px 30px",
                                }}
                                >
                                    <Typography variant='h5'
                                        sx={{
                                            marginLeft: "10px", display: "flex",
                                            alignItems: "center", marginTop: 0.5,
                                            color: "white", justifyContent: "center",
                                            textAlign: "center"
                                        }}
                                    >
                                        Misión
                                    </Typography>
                                </Box>
                            </Box>

                            <Typography variant='h6' gutterBottom sx={{ marginTop: "2rem", textAlign: "left" }}  >
                                <span style={{ fontWeight: "bold", marginRight: "5px" }}>LABFACOM</span> es una moderada e innovadora compañía farmacéutica dedicada al desarrollo importación, fabricación y comercialización de
                                productos farmacéuticos, dispositivos médicos, cosméticos, y dispositivos médicos quirúrgicos, para el cuidado de la salud, tanto para prevenir como
                                tratar enfermedades, asegurando la eficacia terapéutica con altos estándares de control, innovación y desarrollo sostenible.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} >
                            <Box sx={{
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'center', marginTop: '6em',
                            }}>
                                <Box sx={{
                                    backgroundColor: "#d42b29",
                                    height: "45px", width: "400px",
                                    borderRadius: "30px 15px 30px 15px",
                                }}
                                >
                                    <Typography variant='h5'
                                        sx={{
                                            marginLeft: "10px", display: "flex",
                                            alignItems: "center",
                                            color: "white", justifyContent: "center",
                                            textAlign: "center"
                                        }}
                                    >
                                        Visión
                                    </Typography>
                                </Box>
                            </Box>

                            <Typography variant='h6' gutterBottom sx={{ marginTop: "2rem", textAlign: "justify" }}>
                                Para el año 2030 <span style={{ fontWeight: "bold", marginLeft: "5px", marginRight: "5px" }}> LABFACOM </span>
                                pretende el reconocimiento de marca con la innovación continua del portafolio de productos, asegurando
                                su crecimiento, desarrollo responsable y sostenible
                            </Typography>
                        </Grid>
                    </Grid>


                    <Grid container spacing={3} sx={{}}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box sx={{
                                display: 'flex', alignItems: 'center',
                                justifyContent: 'center', marginTop: '2em',
                            }}>
                                <Box sx={{
                                    backgroundColor: "#003da5", height: "45px",
                                    width: "600px", borderRadius: "15px 30px 15px 30px",
                                }}
                                >
                                    <Typography variant='h5'
                                        sx={{
                                            marginLeft: "10px", display: "flex",
                                            alignItems: "center", marginTop: 0.5,
                                            color: "white", justifyContent: "center",
                                            textAlign: "center"
                                        }}
                                    >
                                        Principios y valores Organizacionales
                                    </Typography>
                                </Box>
                            </Box>

                            <Typography variant='h6' gutterBottom sx={{
                                marginTop: "2rem", textAlign: "center",
                                fontWeight: 'normal'
                            }}  >
                                Laboratorio Farma Comercial de Nit 900.867.094, declara que todo el personal que trabaje y la presente, debe aceptar y mantener las siguientes
                                cualificaciones humanas
                            </Typography>
                        </Grid>

                    </Grid>

                    <Grid container spacing={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <PrincipioValores />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} sx={{ marginTop: "0.5em", }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <NuestrasPoliticas />
                        </Grid>
                    </Grid>
                </Box>

            </Container>


            <Box sx={{ marginTop: "8rem", }}>
                <Footer />
            </Box>
        </div>
    )
}

export default QuienesSomos